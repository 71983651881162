<template>
  <div class="page">
    <b-container fluid>
      <b-row class="data-page">
        <b-col class="col-6 data-col">
          <ButtonItem
            :count="datasetsCount"
            :text="{
              title: $tc('words.dataset', datasetsCount),
              article: $tc('words.pronounMasculine', datasetsCount)
            }"
            :icon="'data_layers'"
            :route-to="['Datasets', 'AddDataset']"
            :loading="!isDatasetsListLoaded"
          />
          <ButtonItem
            :text="{
              title: $t('datasets.add'),
            }"
            :icon="'add_plus'"
            :route-to="['AddDataset']"
            :loading="!isDatasetsListLoaded"
            :no-title="true"
          />
        </b-col>
        <b-col class="col-4 dashboard-col">
          <h4>{{ $t('data.latestTasks') }}</h4>
          <div class="tasks-count">
            <b-overlay
              :show="reloadingTasks"
              :variant="'white'"
              rounded="lg"
              opacity="0.9"
            >
              <template #overlay>
                <div class="d-flex align-items-center">
                  <b-spinner
                    small
                    variant="secondary"
                  />
                </div>
              </template>
              <div style="line-height: 1.1em; vertical-align: middle;">
                {{
                  dashboardTasksQueue.length > 0 && taskInProgressCount > 0 ?
                    taskInProgressCount : $t('words.noneFeminine')
                }}
                <!-- eslint-disable-next-line vue/no-parsing-error--> 
                {{ $tc('words.task', taskInProgressCount < 2 ? 1 : taskInProgressCount) }} 
                {{ $t('words.inProgress') }}
              </div>
            </b-overlay>
          </div>
          <b-overlay
            id="overlay-background"
            :show="!isTasksQueueLoaded"
            :variant="'white'"
            :opacity="0.6"
            rounded="sm"
            blur="2px"
            style="width: 100%; min-height: 6rem;"
          >
            <b-toaster name="tasks-toaster" />
            <CustomToast
              v-for="task of dashboardTasksQueue.slice(0, 5).reverse()"
              :key="task.id"
              :task="task"
              :static="true"
              :toaster="'tasks-toaster'"
              :no-close-button="true"
            />
            
            <div
              v-if="dashboardTasksQueue.length === 0 && isTasksQueueLoaded"
              class="no-tasks"
            >
              {{ $t('data.noTasks') }}
            </div>
          </b-overlay>
          <b-button
            v-if="isTasksQueueLoaded && dashboardTasksQueue.length > 0"
            class="btn-lg"
            style="margin-top: 1rem;"
            @click="$router.push({ name: 'Tasks' })"
          >
            {{ $t('data.seeAllTasks') }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ButtonItem from '@/components/ButtonItem.vue';
import CustomToast from '@/components/CustomToast.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Data',

  components : {
    ButtonItem,
    CustomToast,
  },

  data() {
    return {
      isDatasetsListLoaded: false,
      isTasksQueueLoaded: false,
      reloadingTasks: false,
    };
  },

  computed: {
    ...mapState('datasets', [
      'datasetsCount',
      'datasetsList',
      'datasetsCategories',
      'datasetsGranularities',
      'datasetsLicences',
      'datasetsTopics',
      'datasetsKinds'
    ]),
    ...mapState('dashboard', [
      'dashboardTasksCount',
      'dashboardTasksQueue'
    ]),
    ...mapState('resources', [
      'schedulers'
    ]),

    taskInProgressCount() {
      return this.dashboardTasksQueue.filter(el => el.state !== 'SUCCESS' && el.state !== 'FAILURE').length;
    }
  },

  created() {
    this.getData();
    this.getConfigData();
  },

  mounted() {
    this.SET_RELOAD_INTERVAL_ID({
      name: 'dashboard_relaod',
      interval: setInterval(() => {
        this.reloadingTasks = true;
        this.GET_DASHBOARD_TASKS({
          direction: null,
          field: null
        })
          .then(() => {
            this.reloadingTasks = false;
          })
          .catch(() => {
            this.reloadingTasks = false;
          });
      }, process.env.VUE_APP_RELOAD_INTERVAL)
    });
  },

  methods: {
    ...mapMutations([
      'SET_RELOAD_INTERVAL_ID',
    ]),
    ...mapActions('datasets', [
      'GET_DATASETS_COUNT',
      'GET_DATASETS_CATEGORIES',
      'GET_DATASETS_GRANULARITIES',
      'GET_DATASETS_LICENCES',
      'GET_DATASETS_TOPICS',
      'GET_DATASETS_KINDS'
    ]),
    ...mapActions('dashboard', [
      'GET_DASHBOARD_TASKS'
    ]),
    ...mapActions('resources', [
      'GET_SCHEDULERS'
    ]),

    getData() {
      this.GET_DATASETS_COUNT()
        .then(() => {
          this.isDatasetsListLoaded = true;
        }).catch(() => {
          this.isDatasetsListLoaded = true;
        });
      this.isTasksQueueLoaded = false;
      this.GET_DASHBOARD_TASKS({
        direction: null,
        field: null,
        cancellable: false
      })
        .then(() => {
          this.isTasksQueueLoaded = true;
        })
        .catch(() => {
          this.isTasksQueueLoaded = true;
        });
    },
    getConfigData() {
      if (this.datasetsCategories.length === 0) {
        this.GET_DATASETS_CATEGORIES();
      }
      if (this.datasetsGranularities.length === 0) {
        this.GET_DATASETS_GRANULARITIES();
      }
      if (this.datasetsLicences.length === 0) {
        this.GET_DATASETS_LICENCES();
      }
      if (this.datasetsTopics.length === 0) {
        this.GET_DATASETS_TOPICS();
      }
      if (this.datasetsKinds.length === 0) {
        this.GET_DATASETS_KINDS();
      }
      if (this.GET_SCHEDULERS.length === 0) {
        this.GET_SCHEDULERS();
      }
    }
  }
};
</script>

<style scoped lang="less">

.container-fluid {
  height: calc(100% - @footerHeight);
}
.dashboard-col {
  margin-top: 0.5rem;
}
.tasks-count {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.no-tasks {
  text-align: center;
  margin-top: 1rem;
  padding-top: 1rem;
  font-style: italic;
  color: rgb(82, 82, 82);
  font-size: 0.9em;
}

</style>
